import React from 'react'

export const STRING_OCCUPATION = "Software Engineer";
export const STRING_GO_TO_BLOG = "Go to Blog";
export const STRING_DOWNLOAD_RESUME = "Download Resume";
export const STRING_BIO = "Freelancer since I was 14. Graduated in 2019. Tecnhology, programming & problem-solving are what excites me the most.";
export const STRING_EDUCATION_TITLE = "Education";
export const STRING_EXPERIENCE_TITLE = "Experience";
export const STRING_TECHNOLOGIES_TITLE = "Technologies";
export const STRING_EDUCATION_ISEC_DESCRIPTION = (
    <div>
        <p>
            Bachelor’s degree in Informatics Engineering (specialized in Application Development).
        </p>
        <p>
            The course focus mainly in the development of software in different (high and low level)
            programming languages, from Assembly to Java and the most important development and architecture
            concepts.
        </p>
    </div>
);
export const STRING_EXPERIENCE_REMOTE = (
    <div>
        <p>When I was 14, I created a little metablog that helped others manage their blogs with
            tutorials and content related with blogging technologies in general.</p>
        <p>That blog ended up gaining a bit of traction and I would be frequently approached by people,
            asking me to design their websites in exchange for money.</p>
        <p>
            I ended up working continuously has a Freelancer Web Designer & Web Writer, when the
            jobs were compatible with my schedule. In 2018, I opted out of that work to focus on my
            academic path.
        </p>
    </div>
);
export const STRING_EXPERIENCE_ENSO = (
    <div>
        <p>
            In the final stage of my curricular plan as a BSc student, I spent a semester working full-time
            for <strong>ENSO ORIGINS</strong>, an emerging Portuguese startup that provides mainly mobile and web
            software solutions for other businesses.
        </p>
        <p>
            My project was to develop:
        </p>
        <ul>
            <li>Rest API (PHP – Slim Framework)</li>
            <li>Data Model / Persistence (pgSQL)</li>
            <li>Web Platform (JS/jQuery + HTML + CSS)</li>
            <li>Mobile App (React Native)</li>
            <li>Notifications System (Push Notifications + Email Notifications) </li>
        </ul>
        <p>
            The software was already being used in production internally by the time my internship
            ended.
        </p>
    </div>
);
export const STRING_TECHNOLOGIES_ALSO_WORKED_WITH = "Also worked with";
export const STRING_EDUCATION_ISEC_DEGREE = "BSC INFORMATICS ENGINEERING";
export const STRING_EXPERIENCE_ENSO_ROLE = "Curricular Internship";

/* ITSECTOR */
export const STRING_EXPERIENCE_IT_CONSULTANT = "Android Engineer";
export const STRING_EXPERIENCE_ITSECTOR = (
    <div>
        <ul>
            <li>Maintenance and continuous development of <strong>Banco Montepio</strong>’s android
                home banking app</li>
            <li><strong>Mentorship</strong> of new hires and interns</li>
            <li><strong>Technical interviews</strong></li>
        </ul>
    </div>
);
/* Match Profiler/JUMIA */
export const STRING_EXPERIENCE_ANDROID_DEVELOPER = "Android Developer";
export const STRING_EXPERIENCE_ANDROID_ENGINEER = "Android Engineer";
export const STRING_EXPERIENCE_JUMIA = "Maintenance and continuous development of Jumia Online Shopping android app.";
/* Match Profiler/BAE */
export const STRING_EXPERIENCE_BAE = "Maintenance and continuous development of ATLANTICO Europa bank’s android home banking app";