/* GENERAL */
export const STRING_OCCUPATION = "STRING_OCCUPATION";
export const STRING_GO_TO_BLOG = "STRING_GO_TO_BLOG";
export const STRING_DOWNLOAD_RESUME = "STRING_DOWNLOAD_RESUME";
export const STRING_BIO = "STRING_BIO";
export const STRING_TECHNOLOGIES_ALSO_WORKED_WITH = "STRING_TECHNOLOGIES_ALSO_WORKED_WITH";
export const STRING_TECHNOLOGIES_TITLE = "STRING_TECHNOLOGIES_TITLE";
export const STRING_EDUCATION_TITLE = "STRING_EDUCATION_TITLE";
export const STRING_EXPERIENCE_TITLE = "STRING_EXPERIENCE_TITLE";

export const STRING_EXPERIENCE_ANDROID_DEVELOPER = "STRING_EXPERIENCE_ANDROID_ENGINEER";
export const STRING_EXPERIENCE_ANDROID_ENGINEER = "STRING_EXPERIENCE_ANDROID_ENGINEER";
export const STRING_EXPERIENCE_IT_CONSULTANT = "STRING_EXPERIENCE_IT_CONSULTANT";

/* EDUCATION: ISEC */
export const STRING_EDUCATION_ISEC_DEGREE = "STRING_EDUCATION_ISEC_DEGREE";
export const STRING_EDUCATION_ISEC_DESCRIPTION = "STRING_EDUCATION_ISEC_DESCRIPTION";

/* JOB: FREELANCING */
export const STRING_EXPERIENCE_REMOTE = "STRING_EXPERIENCE_REMOTE";

/* JOB: ENSO */
export const STRING_EXPERIENCE_ENSO = "STRING_EXPERIENCE_ENSO";
export const STRING_EXPERIENCE_ENSO_ROLE = "STRING_EXPERIENCE_ENSO_ROLE";

/* JOB: ITSECTOR */
export const STRING_EXPERIENCE_ITSECTOR = "STRING_EXPERIENCE_ITSECTOR";

/* JOB: Match Profiler/JUMIA  */
export const STRING_EXPERIENCE_JUMIA = "STRING_EXPERIENCE_JUMIA";

/* JOB: BAE */
export const STRING_EXPERIENCE_BAE = "STRING_EXPERIENCE_BAE";

